
.section{
	margin-bottom: 40px;
}
.section-fluid{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.fluid-inner{
		width: 100%;
		max-width: 1440px;
	}
}



.landing-page{
	width: 100vw;
	overflow: hidden;
	padding-top: 64px;
	@media screen and (max-width:767px){
		padding-top: 84px;
	}
	.cta-all-deals{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		@media screen and (max-width:767px) {
			margin-top: 5px;
		}
	}
	.btn-all-deals{
		width: auto;
		display: inline-block;
		border: 1px solid #2b2b2b;
		cursor: pointer;
		border-radius: 8px;
		padding: 12px 52px;
		font-family: 'Hellix';
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.5px;
	}
}

.details-page{
	width: 100vw;
	overflow: hidden;
	padding-top: 64px;
	@media screen and (max-width:767px){
		padding-top: 84px;
	}
}

.deals-page{
	width: 100vw;
	overflow: hidden;
	padding-top: 104px;
	@media screen and (max-width:767px){
		padding-top: 124px;
	}
}

.react-contextmenu{
	background-color: #FFFFFF;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.06);
	border: 1px solid rgba(0,0,0,0.2);
	padding: 5px 5px;
	z-index: 3;
	border-radius: 3px 3px;
}
.react-contextmenu-item{
	padding: 5px 5px;
	border-radius: 3px 3px;
	-ms-user-select:none;
	-moz-user-select:none;
	-webkit-user-select:none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	user-select:none;
}
.react-contextmenu-item:hover{
	color: white;
	background-color: gray;
}
.react-contextmenu-wrapper{
	position: relative;
	width: calc(100% - 18px);
	height: 100%;
	display: block;
	background-color: white;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 8px 16px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;

}