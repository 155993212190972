.promotion-wrapper{
		position: relative;
		padding: 0px 33px;
		max-width: 1440px;
		margin-top: 98px;
		@media screen and (max-width:991px) {
			margin-top: 78px;
			padding: 0px 16px;
		}
		.bg{
			position: absolute;
			width:calc(100% - 66px);
			left: 33px;
			height: 100%;
			overflow: hidden;
			background: linear-gradient(110.4deg, #6029AB 34.28%, #8147C7 75.02%);
			border-radius: 10px;
			@media screen and (max-width:991px) {
				width:calc(100% - 32px);
				left: 16px;
			}
			img{
				position: absolute;
			}
			.rect1{
				width: 495px;
				left: 50%;
				top: 50%;
				margin-left: -778px;
				margin-top: -387px;
				@media screen and (max-width:991px) {
					margin-left: -508px;
				}
			}
			.rect2{
				width: 577px;
				left: 50%;
				top: 50%;
				margin-left: 28px;
				margin-top: -80px;
				transform-origin: center;
				@media screen and (max-width:991px) {
					margin-left: 208px;
				}
			}
		}
		.content{
			position: relative;
			padding:77px 84px;
			color: white;
			@media screen and (max-width:991px) {
				padding:52px 24px;
			}
			.card-wrapper{
				width: 328px;
				height: 492px;
				position: absolute;
				top: -48px;
				right: 118px;
				display: flex;
				justify-content: center;
				align-items: center;
				@media screen and (max-width:991px) {
					position: relative;
					margin-top: -58px;
					width: 100%;
					height: auto;
					right: 0;
				}
				img{
					width:100%;
					height: 100%;
					@media screen and (max-width:991px) {
						width: calc(100% - 48px);
						max-width: 328px;
						min-width: 210px;
						height: auto;
						right: 0;
					}
				}
			}
			.amaze-title{
				position: relative;
				font-weight: bold;
				font-size: 64px;
				line-height: 70px;
				@media screen and (max-width:991px) {
					text-align: center;
					font-size: 26px;
					line-height: 35px;
				}
			}
			.amaze-cta{
				position: relative;
				max-width: 520px;
				font-size: 32px;
				line-height: 35px;
				margin-top: 40px;
				@media screen and (max-width:991px) {
					max-width: 100%;
					text-align: center;
					margin-top: 16px;
					font-size: 18px;
					line-height: 105%;
				}
			}
			.amaze-download{
				margin-top: 72px;
				display: flex;
				justify-content: flex-start;
				@media screen and (max-width:991px) {
					margin-top: 32px;
					justify-content: center;
				}
				.amaze-download-btn{
					position: relative;
					text-align: center;
					padding: 12px 30px;
					padding-bottom: 16px;
					display: inline-block;
					width: auto;
					color: black;
					font-size: 16px;
					line-height: 22px;
					font-weight: bold;
					background: #FFD700;
					box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
					border-radius: 8px;
					cursor: pointer;
				}
			}
			.amaze-why{
				position: relative;
				margin-top: 56px;
				font-weight: bold;
				font-size: 26px;
				line-height: 35px;
				@media screen and (max-width:991px) {
					text-align: center;
				}
			}
			.amaze-benefits{
				padding-top: 16px;
				@media screen and (max-width:991px) {
					padding-top: 8px;
					max-width: 500px;
					margin: 0 auto;
				}
				.amaze-benefit{
					padding: 16px 0px;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					@media screen and (max-width:991px) {
						padding: 8px 0px;
					}
					.amaze-benefit-icon{
						width: 56px;
						height: 56px;
						min-width: 56px;
						img{
							width: 100%;
							height: 100%;
						}
					}
					.amaze-benefit-txt{
						margin: 0px 20px;
						flex: 1;
					}
				}
			}
		}
}