.nav-wrapper{
	height: 64px;
	@media screen and (max-width:767px){
		height: 84px;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top:0;
	left: 0;
	z-index: 100;
	//background-color: rgba(255,255,255,0.9);
	background-color: white;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.06);
	.logo-wrapper{
		//background-color: red;
		max-width: 1320px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
	}
}
