.footer-wrapper{
	background-color: black;
	color: white;
	// max-width: 1440px;
	padding-top: 20px;
	padding-bottom: 40px;
	margin-top: 20px;
	a{color:white!important;outline: none!important; text-decoration: none!important;}
	a:link {text-decoration:none;color: white;}
	a:hover { text-decoration:none;color: white;} 
	a:active { text-decoration:none;color: white;} 
	a:visited {text-decoration:none;color: white;}
	.content-wrapper{
		max-width: 1320px;
	}


	.footer-header{
		display: flex;
		border-bottom: 1px solid #494948;
		padding-bottom: 16px;
		margin-top: 10px;
		@media screen and (max-width:991px) {
			border-bottom: none;
			padding-bottom: 0px;
			margin-top: 30px;
		}
		.footer-header-logo{
			@media screen and (max-width:991px) {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.footer-header-links{
			display: flex;
			flex: 1;
			justify-content: flex-end;
			align-items: center;
			font-size: 16px;
		}
	}

	.footer-sns{
		padding: 30px 0px;
		display: flex;
		justify-content: space-between;
		@media screen and (max-width:991px) {
			flex-direction: column;
			align-items: center;
		}
		.footer-sns-icons{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			@media screen and (max-width:991px) {
				align-items: center;
			}
			padding: 10px 0px;
			.footer-sns-icons-imgs{
				display: flex;
				justify-content: center;
				align-items: center;
				img{
					margin-right: 16px;
				}
			}
		}
		.footer-contact{
			padding: 10px 0px;
			@media screen and (max-width:991px) {
				text-align: center;
			}
		}
		.footer-privacy{
			padding: 10px 0px;
			@media screen and (max-width:991px) {
				text-align: center;
			}
		}
	}
	.footer-nium{
		display: flex;
		@media screen and (max-width:991px) {
			text-align: center;
			flex-direction: column;
		}
		.footer-nium-lines{
			flex: 1;
			padding-right: 120px;
			@media screen and (max-width:991px) {
				padding-right: 0;
			}
			.footer-nium-line{
				padding: 6px 0px;
			}
		}
		.footer-nium-poweredby{
			img{
				padding-top: 20px;
				width: 60px;
				height: auto;
			}

		}
	}
	.footer-localization,.footer-sitemap{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 42px;
		flex-wrap: wrap;
		.basic-btn{
			//border-bottom: 1px solid #494948;
			position: relative;
			&:after{
				content: '';
				display: block;
				border-left: 1px solid white;
				position: absolute;
				height: calc(100% - 16px);
				top: 10px;
				right: -15px;
			}
			&.noafter{
				&:after{
					display: none;
				}

			}
			//border-right: 1px solid white;
		}
	}
	.footer-iso{
		margin-top: 42px;
		font-size: 10px;
		line-height: 40px;
		@media screen and (max-width:991px) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		img{
			width: 130px;
			height: auto;
		}
	}

	.basic-btn{
		margin: 3px 15px;
		padding-bottom: 2px;
		padding-top: 2px;
	}
}