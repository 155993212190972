.article-wrapper{
	del{
		text-decoration-line: underline!important;
	}
	.header-wrapper{
		//max-width: 1440px;
		position: relative;
		padding: 0px 0px;
		img{
			width: 100%;
			height: auto;
			min-height: 340px;
			object-fit: cover;
			@media screen and (max-width:767px) {
				min-height: 192px;
			}
		}
	}
	.content{
		overflow: hidden;
		max-width: 1030px;
		padding: 0px 32px;
		position: relative;
		@media screen and (max-width:767px) {
			padding: 0px 16px;
		}
		.inner{
			width: 100%;
			position: relative;
		}
		.article-vendor{
			color: #2a2a2a;
			font-size: 18px;
			line-height: 22px;
			margin-top: 24px;
			margin-bottom: 18px;
			width: fit-content;
		}
		.article-body{
			position: relative;
			display: flex;
			flex-direction: row;
			padding-bottom: 55px;
			border-bottom: 1px solid rgba(159,159,159,0.3);
			@media screen and (max-width:767px) {
				//width: calc(100% - 16px);
				width: 100%;
				border-bottom: none;
				padding-bottom: 0px;
			}
			.article{
				max-width: 100%;
				flex: 1;
				padding-right: 80px;
				@media screen and (max-width:767px) {
					padding-right: 0px;
				}
				.article-title{
					font-weight: bold;
					font-size: 48px;
					line-height: 110%;
					color: #2a2a2a;
				}
				.article-offer-details,.article-howto,.article-tnc{
					margin-top: 42px;
					@media screen and (max-width:767px) {
						margin-top: 24px;
					}
					.article-offer-details-title,.article-howto-title,.article-tnc-title{
						display: flex;
						justify-content: flex-start;
						align-items: center;
						font-weight: bold;
						font-size: 26px;
						line-height: 35px;
						color: #2b2b2b;
					}
					.article-offer-details-content,.article-howto-content,.article-tnc-content{
						font-size: 18px;
						line-height: 23px;
						color: #2a2a2a;
					}
					.p{
						margin: 10px 0px;
					}
					.details_new-inner,.howtos_new-inner,.tncs_new-inner{
						margin: 20px 0px;
						a{
							color: #2a2a2a;
						}
					}
				}

				.article-cta-inline,.share-wrapper{
					border: 1px solid #000000;
					border-radius: 10px;
					padding: 16px;
					margin-top: 24px;
				}
				
			}
			.article-cta-float{
				width: 332px;
				text-align: center;
				border: 1px solid #000000;
				border-radius: 10px;
				padding: 16px;
				height: fit-content;
				//align-self: baseline;
			}
			.shop-now{
				padding: 13px 48px;
				text-align: center;
				background-color: #FE0095;
				border-radius: 8px;
				font-weight: bold;
				color: white;
				cursor: pointer;
			}
			.expire-date{
				display: flex;
				justify-content: center;
				align-items: flex-start;
				font-size: 16px;
				line-height: 19px;
				margin: 8px;
				width: 100%;
				img{
					width: 17px;
					height: 17px;
					margin-right: 8px;
					margin-top: 2px;
				}
				div{
					display: inline-block;
				}
				.cd-wrapper{
					text-align: left;
				}
				.cd{
					font-size: 10px;
					color: #fe0095;
				}
			}
			.sns-share{
				.sns-share-title{
					text-align: center;
					font-weight: bold;
					font-size: 18px;
					line-height: 22px;
					margin-top: 32px;
					margin-bottom: 16px;
					@media screen and (max-width:767px) {
						margin-top: 0px;
					}
				}
				.sns-share-btns{
					display: flex;
					align-items: center;
					justify-content: center;
					img{
						width: 37.5px;
						height: 37.5px;
						margin: 0px 10px;
						object-fit: cover;
						border-radius: 100%;
					}
				}
			}
		}
	}
}