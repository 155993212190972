.mapContainer{
	position: relative;
	padding: 0px 33px;
	max-width: 1440px;
	margin-top: 28px;
	@media (min-width:768px) and (max-width:991px) {
		padding: 0px 16px;
	}
	@media screen and (max-width:767px) {
		padding: 0px;
	}
	.mapWrapper{
		position: relative;
		width: 100%;
		overflow: hidden;
		height: max-content;
		filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		@media screen and (max-width:767px) {
			flex-direction: column;
			border-radius: 0px;
		}
		.pin{
			width: 25px;
			height: 32px;
			margin-left: -13px;
			margin-top: -32px;
			.pin-icon{
				width: 25px;
				height: 32px;
			}
			.pin-text{
				position: absolute;
				min-width: 100px;
				max-width: 240px;
				transform: translateX(-50%);
				margin-left: 13px;
				height: auto;
				padding: 10px;
				background-color: #000000;
				color: white;
				text-align: center;
				border-radius: 35px;
				bottom: calc( 100% + 25px);
			}
		}
		.myBingMap{
			position: relative;
			height: max-content;
			height: 607px;
			max-height: 60vh;
			flex: 1;
			@media screen and (max-width:767px) {
				flex:none;
			}
			background: #C4C4C4;
		}
		.dealInfo{
			width: 332px;
			position: relative;
			//height: 100%;
			background-color: white;
			height: 607px;
			max-height: 60vh;
			border-radius: 0px 10px 10px 0px;
			@media screen and (max-width:767px) {
				width: calc(100% - 44px);
				margin-left: 22px;
				margin-top: -120px;
				padding-bottom: 20px;
				height: auto;
				max-height: 500vh;
				z-index: 10000;
				border-radius: 10px 10px 10px 10px;
				overflow: hidden;
			}

			.offer-card-inner{
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				@media screen and (max-width:767px){
					height: auto;
				}
				.btn-wrapper{

					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 200px;
					padding-top: 42px;
					background: linear-gradient(0deg, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
					@media screen and (max-width:767px){
						position: relative;
						height: 80px;
					}
				}
				.btn-see-deal{
					width: auto;
					display: inline-block;
					border: 1px solid #2b2b2b;
					cursor: pointer;
					border-radius: 8px;
					padding: 12px 52px;
					font-family: 'Hellix';
					font-weight: bold;
					font-size: 16px;
					line-height: 22px;
					letter-spacing: 0.5px;
					color: black;
					text-decoration: none;
					position: absolute;
					width: 200px;
					text-align: center;
					left: 24px;
					bottom: 24px;
					@media screen and (max-width:767px){
						bottom: 12px;
					}
				}
				.article-offer-details,.article-howto{
					padding: 12px 24px;
					a{
						color: black;
					}
					@media screen and (max-width:767px){
						display: none;
					}
				}
				.tag{
					position: absolute;
					color: #2a2a2a;
					padding: 6px 20px;
					font-weight: bold;
					left: 8px;
					top: 8px;
					font-size: 12px;
					background: #FFFFFF;
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
					border-radius: 14px;
				}
				.category{
					width:28px;
					height: 28px;
					position: absolute;
					right: 8px;
					top: 8px;
				}
				.info{
					padding: 12px 24px;
					.vendor{
						font-size: 14px;
						margin-bottom: 10px;
					}
					.offer-title{
						font-weight: bold;
						margin-bottom: 20px;
						@media screen and (max-width:767px){
							margin-bottom: 10px;
						}
					}
				}
			}
		}



		//reset bing maps
		.Infobox{
			border: none;
			color: white;
			background: #000000;
			border-radius: 35px;
		}
		.infobox-stalk{
			display: none;
		}
		.infobox-body{
			padding-bottom: 0!important;
		}
		.infobox-title{
			width: 100%;
			text-align: center;
			color: white!important;
			margin-top: -35px;
		}
		.infobox-close{
			display: none;
		}
	}
}