.search-wrapper{
	max-width: 1030px;
	.title-row{
		.title{
			text-align: center;
			font-size: 32px;
			line-height: 35px;
			margin-bottom: 30px;
			font-weight: bold;
		}
	}
	.search-row{
		padding: 0px 24px;
		@media screen and (max-width:767px) {
			padding: 0px 12px;
		}
		.input-wrapper{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			height: 56px;
			position: relative;
			img{
				position: absolute;
				left: 16px;
			}
			.di{
				border: none;
				flex: 1;
				width: 100%;
				line-height: 56px;
				font-size: 20px;
				font-weight: bold;
				padding-left: 48px;
				border: 0.5px solid #000000;
				border-radius: 10px;
				&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
					color: #ccc;
					opacity: 1; /* Firefox */
				}
				
				&:-ms-input-placeholder { /* Internet Explorer 10-11 */
					color: #ccc;
				}
				
				&::-ms-input-placeholder { /* Microsoft Edge */
					color: #ccc;
				}
			}

		}
	}
	.cats-row{
		margin-top: 19px;
		margin-bottom: 19px;
		padding: 0px 24px;
		@media screen and (max-width:767px) {
			padding: 0px 12px;
		}
		.inner{
			position: relative;
			width: 100%;
			display: flex;
			justify-content: flex-start;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;
		}
		.cat-wrapper{
			height: 32px;
			border: 0.4px solid #000000;
			border-radius: 30px;
			display: flex;
			font-size: 12px;
			justify-content: flex-start;
			margin: 9px 0px;
			margin-right: 10px;
			@media screen and (max-width:767px) {
				font-size: 10px;
			}
			align-items: center;
			max-width: 200px;
			img{
				margin-left: 4px;
			}
			.info{
				margin-right: 12px;
			}
			// padding-left: 4px;
			// padding-right: 12px;
			width: auto;
			cursor: pointer;
			&.active{
				border-color: white;
				background-color: #fe0095;
				color: white;
				img{
					filter: invert(89%) sepia(100%) saturate(0%) hue-rotate(358deg) brightness(112%) contrast(102%);
				}
			}
		}
	}
}
.deals-wrapper{
	max-width: 1030px;
	.expire-date{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 16px;
		line-height: 19px;
		width: 100%;
		margin-left: -2px;
		img{
			width: 17px;
			height: 17px;
			margin-right: 8px;
		}
		div{
			display: inline-block;
		}
		.cd{
			font-size: 10px;
			color: #fe0095;
		}
	}
	.title-row{
		.title{
			text-align: center;
			font-size: 32px;
			line-height: 35px;
			margin-bottom: 30px;
			font-weight: bold;
		}
		.description{
			text-align: center;
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 30px;
			padding-left: 5%;
			padding-right: 5%;
			font-weight: bold;
		}
	}
	.offer-card{
		.offer-title{
			font-size: 20px;
			@media screen and (max-width:767px){
				font-size: 14px;
			}
		}
	}
	.offer-card,.offer-card-slider{
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		.offer-card-inner{
			position: relative;
			width: 100%;
			height: 100%;
			display: block;
			.tag{
				position: absolute;
				color: #2a2a2a;
				padding: 6px 20px;
				font-weight: bold;
				left: 8px;
				top: 8px;
				font-size: 12px;
				background: #FFFFFF;
				box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
				border-radius: 14px;
			}
			.category{
				width:28px;
				height: 28px;
				position: absolute;
				background-color: white;
				border-radius: 100%;
				right: 8px;
				top: 8px;
			}
			.info{
				padding: 12px 24px;
				@media screen and (max-width:767px){
					padding: 6px 12px;
				}
				.vendor{
					font-size: 14px;
					margin-bottom: 10px;
					@media screen and (max-width:767px){
						margin-bottom: 5px;
					}
				}
				.offer-title{
					font-weight: bold;
					margin-bottom: 20px;
					@media screen and (max-width:767px){
						margin-bottom: 10px;
					}
				}
			}
		}
	}
	.cards{
		padding: 0px 24px;
		@media screen and (max-width:767px) {
			padding: 0px 12px;
		}
	}
	.cards-slider{
		padding: 0px;
		position: relative;
		width: 100%;
		.scroll-wrapper{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			padding: 0px 24px;
			@media screen and (max-width:767px) {
				padding: 0px 12px;
				flex-wrap: nowrap;
			}
		}
		.offer-card-slider{
			min-width: 283px;
			width: 283px;
			transition: all;
			@media screen and (min-width:768px){
				width: 33.3%;
				min-width: 33.3%;
			}
			.offer-title{
				font-size: 20px;
			}
		}
		.more-hint{
			position: absolute;
			width: 40px;
			height: 100%;
			top: 0;
			z-index: 2;
			// @media screen and (min-width:768px) {
			// 	display: none;
			// }
			&.left{
				left: 0;
				background: linear-gradient(90deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 100%);
			}
			&.right{
				right: 0;
				background: linear-gradient(270deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 100%);
			}
		}
	}

	// react-contextmenu--visible : applied to menu root element when visible.
	// react-contextmenu-item : applied to menu items.
	// react-contextmenu-item--active : applied to menu items and title in submenu when submenu is open.
	// react-contextmenu-item--selected : applied to currently selected menu item.
	// react-contextmenu-item--disabled : applied to menu items and title in submenu when they are disabled.
	// react-contextmenu-item--divider : applied to menu items with the divider prop.
	// react-contextmenu-wrapper : applied to wrapper around elements in ContextMenuTrigger.
	// react-contextmenu-submenu 
}