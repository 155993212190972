html,
body {

	width                  : 100%;
	overflow-x             : hidden;
	font-weight            : normal;
	margin                 : 0;
	color                  : #2b2b2b;
	font-family            : 'Hellix', Helvetica, Arial, sans-serif;
	vertical-align         : middle;
	-webkit-font-smoothing : antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	-webkit-user-drag: none;
}



// ::-webkit-scrollbar {
// 	width: 15px;
//   }
//   /* Track */
//   ::-webkit-scrollbar-track {
// 	box-shadow: inset 0 0 5px #ddd; 
// 	border-radius: 15px;
//   }
//   /* Handle */
//   ::-webkit-scrollbar-thumb {
// 	background: orange; 
// 	border-radius: 15px;
//   }
//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
// 	background: #e30d00; 
//   }