/*
xs	<576
sm	>=576
md	>=768
lg	>=992
xl	>=1200
xxl	>=1400
*/
.carousel-wrapper {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 444px;
	.fluid-inner{
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.slide {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #2b2b2b;
		.slide-wrapper{
			
			width: 100%;
			height: 100%;
		}
	}
	.bullets{
		position: absolute;
		width: 100vw;
		height: 31px;
		@media screen and (min-width:810px){
			width: 100%;
		}
		//background-color: white;
		bottom: -1px;
		left: 0;
		z-index:10;
		//border-radius: 200% 200% 0% 0%;
		display: flex;
		justify-content: center;
		align-items: center;
		.bullets-bg{
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			bottom: -1px;
			.bullets-bg-svg{
				width: 100%;
				height: 100%;
			}
		}
		@media screen and (max-width:767px){
			.bullets-bg{
				display: none;
			}
		}
		.bullet{
			position: relative;
			width: 12px;
			height: 12px;
			border-radius: 100%;
			background-color: white;
			border: 1px solid #2b2b2b;
			cursor: pointer;
			margin: 4px;
			&.active{
				background-color: #fe0095;
				border: 1px solid #fe0095;
			}
		}
	}


	.slide-bg {
		position: absolute;
		pointer-events: none;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		object-fit: cover;
	}
	.slide-body{
		position: relative;
		max-width: 1030px;
		height: 100%;
		padding: 0px 24px;
		color: white;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		.slide-body-txt{
			font-size: 64px;
			line-height: 72px;
			font-weight: bold;
			@media screen and (max-width:767px){
				font-size: 36px;
				line-height: 40px;
			}
		}
		.slide-body-cta{
			display: inline-block;
			font-size: 16px;
			line-height: 22px;
			width: fit-content;
			font-weight: bold;
			margin-top: 32px;
			margin-bottom: 86px;
			padding: 12px 48px;
			background-color:#fe0095;
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			cursor: pointer;
			@media screen and (max-width:767px){
				margin-top: 24px;
				margin-bottom: 54px;
			}
		}
	}
	.slide-body-2{
		position: relative;
		max-width: 1030px;
		height: 100%;
		padding: 0px 24px;
		color: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.slide-body-txt{
			font-size: 64px;
			line-height: 72px;
			font-weight: bold;
			@media screen and (max-width:767px){
				font-size: 36px;
				line-height: 40px;
			}
		}
		.slide-body-txt-sub{
			font-size: 44px;
			line-height: 50px;
			margin-top: 20px;
			font-weight: bold;
			@media screen and (max-width:767px){
				font-size: 30px;
				line-height: 34px;
			}
		}
		.slide-body-cta{
			display: inline-block;
			font-size: 16px;
			line-height: 22px;
			width: fit-content;
			font-weight: bold;
			margin-top: 32px;
			margin-bottom: 86px;
			padding: 12px 48px;
			background-color:#fe0095;
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			cursor: pointer;
			@media screen and (max-width:767px){
				margin-top: 24px;
				margin-bottom: 54px;
			}
		}
	}


	.refresh {
		padding: 10px;
		position: absolute;
		background: rgba(0, 0, 0, 0.4);
		border-radius: 10px;
		width: 20px;
		height: 20px;
		top: 10px;
		right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
}
